<template>
  <div class="border" style="width: 198px;float: right;">
    <div class="px-1 pt-1">
      <div class="goods-item" style="width: 178px;height: 300px;" v-for="(item,index) in newGoodsList"
           :key="index" @click="toProductDetail(item)">
        <img v-lazy="item.slide_list.img" style="width: 178px;height: 178px;"/>
        <div class="text-main-color font-s-4 mt-1">{{ item.price }}</div>
        <div class="in2line font-s-1 text-dark-grey mt">{{ item.title }}</div>
        <div class="font-s-1 text-grey mt">已售{{ item.sold_count }}件</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RightGoodsList',
  props: {
    goodsList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  methods: {
    // 跳转到商品详情页
    toProductDetail (productInfo) {
      console.log(productInfo)
      this.$router.push({ path: '/product', query: { productId: productInfo.id } })
    }
  },
  computed:{
    newGoodsList(){
      return this.goodsList.slice(0,5)
    }
  }
}
</script>

<style scoped>
.goods-item{
  cursor: pointer;
}
</style>
