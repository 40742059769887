<template>
  <div>
    <!-- 商品详情页 -->
    <div class="w-100 bg-main-color" style="height: 2px;"></div>
    <!-- 商品详情页导航 -->
    <div class="w-100" style="height: 38px;background-color: #f1f1f1;">
      <div class="w-1200 df-ac" style="height: 38px;">
        <div class="text-dark-grey mr-1 font-s-2">商品</div>
        <div class="iconfont icon-qianjin mr-1"></div>
        <div class="text-dark-grey mr-1 font-s-2">{{productDetail.categoryName}}</div>
        <div class="iconfont icon-qianjin mr-1"></div>
        <div class="text-dark-grey mr-1 font-s-2">{{productDetail.title}}</div>
      </div>
    </div>
    <!-- 商品详情信息开始 -->
    <div class="w-1200 d-f mt-4">
      <div class="left-product-banner">
        <img class="big-img" v-lazy="bigProductImg"/>
        <div class="swiper-box">
          <div class="swiper-button">
            <i class="el-icon-arrow-left"></i>
            <i class="el-icon-arrow-right"></i>
          </div>
          <div class="swiper-list clearfix">
            <img class="swiper-item" v-for="(item,index) in productDetail.img"
                 v-lazy="item.img" :key="index" @mouseover="swiperItemHove(item)"/>
          </div>
        </div>
      </div>
      <div class="right-product-info">

        <div class="product-name">
          {{productDetail.title}}
        </div>
        <div>{{productDetail.promotion}}</div>
        <div class="count-down-bg df-ac-js" v-if="pageInfo.pageStatus =='seckill'&& pageInfo.productStatus == 1">
          <div class="df-ac ml-2 text-white">
            <div class="iconfont icon-shijian- mr-1" style="font-size: 26px;"></div>
            <div>限时秒杀</div>
          </div>
          <div class="mr-2 text-white df-ac">
            <div class="font-s-2">距离活动结束仅剩余：</div>
            <div class="font-w">{{seckillTime.hou}}:{{seckillTime.min}}:{{seckillTime.sec}}</div>
          </div>
        </div>

        <!-- 助力的商品信息 -->
        <div class="count-down-bg df-ac-js" v-if="pageInfo.pageStatus =='boost'">
          {{productDetail.boostNumberPeople}}人砍
          原价：{{productDetail.price}}
          助力可减：{{productDetail.boostAmount}}
          结束时间：{{productDetail.boostActivityAfter}}
        </div>


        <div class="other-info">
          <div class="top clearfix">
            <div class="price-info" v-if="pageInfo.pageStatus =='seckill'">
              秒杀价：<span>￥{{productDetail.seckillPrice}}</span>
            </div>
            <div class="price-info" v-else>商城价：<span>￥{{productDetail.price}}</span></div>
            <div class="df-ac-jc f-l font-s-2 text-white font-w"
                 style="width: 70px;height: 26px;background-color: coral;" v-if="pageInfo.pageStatus =='seckill'">限时秒杀
            </div>
            <div class="tip" v-else>降价通知</div>
            <div class="product-sales">累计评价：<span>{{productDetail.soldCount}}</span></div>
            <div class="product-sales" style="border-left: none">累计销量：<span>{{productDetail.soldCount}}</span></div>
          </div>
          <div class="bottom mt-2 clearfix">
            <div class="price-info" v-if="pageInfo.pageStatus =='seckill'">原价：<span>￥{{productDetail.price}}</span>
            </div>
            <div class="price-info" v-else>市场价：<span>￥{{productDetail.marketPrice}}</span></div>
            <div class="phone-info">
              <el-popover
                placement="bottom"
                width="140px"
                trigger="hover">
                <el-image
                  style="width: 120px; height: 120px"
                  :src="qrImg"
                  fit="cover"
                />
                <div slot="reference">手机购买<i class="el-icon-picture-outline"></i></div>
              </el-popover>
            </div>
          </div>
        </div>
        <div class="product-label mt-1">
          <span class="label-title">标签</span>
          <span class="label-content" v-for="(item,index) in productDetail.label" :key="index">{{item}}</span>
        </div>
        <div class="product-label mt-1">
          <span class="label-title">库存</span>
          <span class="label-content">{{productDetail.totalStock}}</span>
        </div>
        <div class="product-label mt-1" v-if="!singleSpecificationStatus">
          <span class="label-title">规格</span>
          <div class="label-content">
            <div class="sku-box" v-for="item in goodsInfo.chooseGoodsSkuList" :key="item.id">
              <div class="sku-title">{{item.skuKey}}</div>
              <div class="sku-list">
                <el-tag class="mb-1 mr-1" type="info"
                        v-for="(itemLabel, index) in item.skuLabel"
                        :key="itemLabel.id"
                        :class="{ 'label-check': itemLabel.checked }"
                        @click="skuLabelClick(item, itemLabel, index)"
                >{{itemLabel.name}}
                </el-tag>
              </div>
            </div>
          </div>
        </div>
        <div class="product-label mt-1" v-if="pageInfo.pageStatus !='seckill'">
          <span class="label-title">数量</span>
          <div class="label-content">
            <div class="amount-warp">
              <input class="border" v-model="chooseAmount"/>
              <div class="quick-button">
                <i class="border el-icon-arrow-up" @click="addAmountCount"></i>
                <i class="border el-icon-arrow-down" @click="subtractAmountCount"></i>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="product-label mt-2" v-if="pageInfo.pageStatus =='seckill'">
            <span class="label-title">&nbsp;</span>
            <div class="label-content">
              <el-button v-if="pageInfo.productStatus == 1" type="danger" @click="seckillBuyNow">立即购买</el-button>
              <el-button v-else-if="pageInfo.productStatus == 2" type="info">尚未开始</el-button>
              <el-button v-else-if="pageInfo.productStatus == 0" type="info">已结束</el-button>
            </div>
          </div>
          <div class="product-label mt-2" v-if="pageInfo.pageStatus =='boost'">
            <span class="label-title">&nbsp;</span>
            <div class="label-content">
              <el-button type="warning" style="background-color: #85c360;border:none;" @click="toBuy">不助力，直接购买
              </el-button>
              <el-button type="danger" style="background-color: #df3033;border:none" @click="boostLaunch">
                发起助力
              </el-button>
            </div>
          </div>
          <div class="product-label mt-2" v-else>
            <span class="label-title">&nbsp;</span>
            <div class="label-content">
              <el-button type="warning" style="background-color: #85c360;border:none;" @click="toBuy">立即购买</el-button>
              <el-button type="danger" style="background-color: #df3033;border:none" @click="addCart"><i
                style="font-size: 14px" class="iconfont icon-gouwuche"></i>
                加入购物车
              </el-button>
            </div>
          </div>
        </div>
        <div class="product-label mt-1">
          <span class="label-title">温馨提示</span>
          <span class="label-content">·不支持退换货服务</span>
        </div>
      </div>
    </div>
    <!-- 商品详情信息结束 -->
    <div class="w-1200 mt-2 d-f justify-between">
      <div class="border" style="width: 198px;">
        <div class="w-100 df-ac-jc bg-main-color" style="height: 40px;">热门推荐</div>
        <right-goods-list :goodsList="hotGoodsList"></right-goods-list>
      </div>
      <div class="" style="width: 990px;">
        <div class="w-100 df-ac bg-f1 border-bottom-main" style="height: 40px; ">
          <div class=" px-2 df-ac-jc font-s-3" style="height: 40px;" v-for="(item,index) in goodsIntroduce" :key="index"
               @click="clickIntroduce(index)" :class="introduceIndex == index ? 'bg-main-color' : ''">
            {{ item.name }}
          </div>
        </div>
        <div class="w-100" v-if="introduceIndex == 0">
          <!-- 这里调取商品详情 -->
          <div class="product-details px-2 py-2" v-html="productDetail.description"
               v-lazy-container="{ selector: 'img' }"></div>
        </div>
        <div class="w-100 px-2 py-2" v-if="introduceIndex == 1">
          -- 暂无商品评价 --
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getHotGoods, productDetail as productDetailUrl, addToCartUrl,
  getSeckillGoodsDetail, getNormalGoodsWechatCode, getSkillGoodsWechatCode,
  boostProductDetailUrl, boostLaunchUrl
} from '@/api/index'
import rightGoodsList from '@/components/productList/RightGoodsList.vue'

export default {
  components: {
    rightGoodsList
  },
  data () {
    return {
      hotGoodsList: [],
      introduceIndex: 0,
      goodsIntroduce: [
        { name: '商品介绍' },
        { name: '商品评价' }
      ],
      productDetail: {},
      bigProductImg: '',
      chooseAmount: 1,
      goodsInfo: {
        description: '', //商品详情图
        chooseGoodsSkuList: [], // 供用户选择的SKU列表
        goodsSkusList: [] // 商品有的规格列表
      },
      // 单规格
      singleSpecificationStatus: false,
      userChooseSku: {}, // 用户选择中的sku对象
      userCheckSku: {}, // 用户已经选中的sku
      addCartHttpObj: {
        productId: '',
        skuId: '',
        amount: ''
      },
      goodsDirectBuyHttpObj: {
        sku: [
          {
            amount: '',
            productId: '',
            skuId: ''
          }
        ]
      },
      pageInfo: {
        pageStatus: '',
        productStatus: '',
        activeId: '',
        boostId: ''
      },
      seckillTime: {
        hou: 0,
        min: 0,
        sec: 0
      },
      qrImg: ''
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      if (this.$route.query.productId != undefined) {
        this.addCartHttpObj.productId = this.$route.query.productId
      }
      if (this.$route.query.pageStatus != undefined) {
        this.pageInfo.pageStatus = this.$route.query.pageStatus
      }
      if (this.$route.query.productStatus != undefined) {
        this.pageInfo.productStatus = this.$route.query.productStatus
      }
      if (this.$route.query.activeId != undefined) {
        this.pageInfo.activeId = this.$route.query.activeId
      }
      if (this.$route.query.boostId != undefined) {
        this.pageInfo.boostId = this.$route.query.boostId
      }
      this.getProductDetail()
      this.getHotGoods()
      this.getQrImg()
    },
    // 获取商品详情
    async getProductDetail () {
      if (this.pageInfo.pageStatus !== 'boost' && this.addCartHttpObj.productId === '') {
        return this.$message.error('商品错误')
      }
      if (this.pageInfo.pageStatus == 'seckill') {
        let httpObj = {
          activityId: this.pageInfo.activeId,
          productId: this.addCartHttpObj.productId
        }
        const { data: res } = await this.$http.get(getSeckillGoodsDetail, { params: httpObj })
        if (res.code !== 200) return this.$message.error(res.msg)
        this.productDetail = res.data
        this.bigProductImg = res.data.img[0].img
        this.goodsInfo.goodsSkusList = res.data.skus

        // 获取当前时间
        let date1 = new Date()
        // 去掉时间戳的毫秒
        let nowTime = Number(String(date1.getTime()).substring(0, 10))
        console.log(nowTime)
        // 计算当前时间和秒杀活动的剩余时间
        let surplusTime = this.productDetail.endAt - nowTime
        if (surplusTime > 0) {
          this.seckillTime.hou = parseInt((surplusTime % (60 * 60 * 24)) / 3600) < 10 ? ('0' + parseInt((surplusTime % (60 * 60 * 24)) / 3600)) : parseInt((surplusTime % (60 * 60 * 24)) / 3600)
          this.seckillTime.min = parseInt(((surplusTime % (60 * 60 * 24)) % 3600) / 60) < 10 ? ('0' + parseInt(((surplusTime % (60 * 60 * 24)) % 3600) / 60)) : parseInt(((surplusTime % (60 * 60 * 24)) % 3600) / 60)
          this.seckillTime.sec = parseInt(((surplusTime % (60 * 60 * 24)) % 3600) % 60) < 10 ? ('0' + parseInt(((surplusTime % (60 * 60 * 24)) % 3600) % 60)) : parseInt(((surplusTime % (60 * 60 * 24)) % 3600) % 60)
        }
        let timer = setInterval(() => {
          --this.seckillTime.sec
          if (this.seckillTime.sec < 0) {
            this.seckillTime.sec = 59
            --this.seckillTime.min
            if (this.seckillTime.min < 0) {
              this.seckillTime.min = 59
              --this.seckillTime.hou
              if (this.seckillTime.hou < 0) {
                // 如果秒杀结束，清除倒计时，状态变为已结束
                clearInterval(timer)
                this.pageInfo.productStatus = 0
              } else if (this.seckillTime.hou < 10) {
                // 小于10在前面追加0
                this.seckillTime.hou = '0' + this.seckillTime.hou
              }
            } else if (this.seckillTime.min < 10) {
              // 小于10在前面追加0
              this.seckillTime.min = '0' + this.seckillTime.min
            }
          } else if (this.seckillTime.sec < 10) {
            // 小于10在前面追加0
            this.seckillTime.sec = '0' + this.seckillTime.sec
          }
        }, 1000)

        if (res.data.attributeList.length == 0 && res.data.skus.length == 0) {
          // 如果是单规格
          this.singleSpecificationStatus = true
        } else {
          // 把后台返回的供选择的sku列表，从对象转化为数组的格式
          let goosSkuObj = res.data.attributeList
          // 自增一个数字，用来组合规格的id，因为微信小程序上面id不能相同
          let newSkuLabelId = 0
          for (let i in goosSkuObj) {
            ++newSkuLabelId
            let o = {
              id: newSkuLabelId,
              skuKey: '',
              skuLabel: []
            }
            o.skuKey = i
            for (let j = 0; j < goosSkuObj[i].length; j++) {
              o.skuLabel.push({
                id: newSkuLabelId + j,
                checked: false,
                name: goosSkuObj[i][j]
              })
            }
            // 把重新生成的商品sku列表添加到chooseGoodsSkuList里面
            this.goodsInfo.chooseGoodsSkuList.push(o)
            // 默认第一个是选中状态
            o.skuLabel[0].checked = true
            this.goodsInfo.chooseGoodsSkuList.forEach(item => {
              this.userChooseSku[item.skuKey] = item.skuLabel[0].name
            })
            this.userCheckSku = res.data.skus[0]
          }
        }

      } else if (this.pageInfo.pageStatus == 'boost') {
        console.log(this.pageInfo)
        const { data: res } = await this.$http.get(boostProductDetailUrl + this.pageInfo.boostId, { params: { id: this.pageInfo.boostId } })
        console.log(res)
        if (res.code !== 200) return this.$message.error(res.msg)
        this.productDetail = res.data
        this.bigProductImg = res.data.img[0].img
        this.goodsInfo.goodsSkusList = res.data.skus
        if (res.data.attributeList.length == 0 && res.data.skus.length == 0) {
          // 如果是单规格
          this.singleSpecificationStatus = true
        } else {
          // 把后台返回的供选择的sku列表，从对象转化为数组的格式
          let goosSkuObj = res.data.attributeList
          // 自增一个数字，用来组合规格的id，因为微信小程序上面id不能相同
          let newSkuLabelId = 0
          for (let i in goosSkuObj) {
            ++newSkuLabelId
            let o = {
              id: newSkuLabelId,
              skuKey: '',
              skuLabel: []
            }
            o.skuKey = i
            for (let j = 0; j < goosSkuObj[i].length; j++) {
              o.skuLabel.push({
                id: newSkuLabelId + j,
                checked: false,
                name: goosSkuObj[i][j]
              })
            }
            // 把重新生成的商品sku列表添加到chooseGoodsSkuList里面
            this.goodsInfo.chooseGoodsSkuList.push(o)
            // 默认第一个是选中状态
            o.skuLabel[0].checked = true
            this.goodsInfo.chooseGoodsSkuList.forEach(item => {
              this.userChooseSku[item.skuKey] = item.skuLabel[0].name
            })
            this.userCheckSku = res.data.skus[0]
          }
        }
      } else {
        const { data: res } = await this.$http.get(productDetailUrl + this.addCartHttpObj.productId)
        if (res.code !== 200) return this.$message.error(res.msg)
        this.productDetail = res.data
        this.bigProductImg = res.data.img[0].img
        this.goodsInfo.goodsSkusList = res.data.skus
        if (res.data.attributeList.length == 0 && res.data.skus.length == 0) {
          // 如果是单规格
          this.singleSpecificationStatus = true
        } else {
          // 把后台返回的供选择的sku列表，从对象转化为数组的格式
          let goosSkuObj = res.data.attributeList
          // 自增一个数字，用来组合规格的id，因为微信小程序上面id不能相同
          let newSkuLabelId = 0
          for (let i in goosSkuObj) {
            ++newSkuLabelId
            let o = {
              id: newSkuLabelId,
              skuKey: '',
              skuLabel: []
            }
            o.skuKey = i
            for (let j = 0; j < goosSkuObj[i].length; j++) {
              o.skuLabel.push({
                id: newSkuLabelId + j,
                checked: false,
                name: goosSkuObj[i][j]
              })
            }
            // 把重新生成的商品sku列表添加到chooseGoodsSkuList里面
            this.goodsInfo.chooseGoodsSkuList.push(o)
            // 默认第一个是选中状态
            o.skuLabel[0].checked = true
            this.goodsInfo.chooseGoodsSkuList.forEach(item => {
              this.userChooseSku[item.skuKey] = item.skuLabel[0].name
            })
            this.userCheckSku = res.data.skus[0]
          }
        }
      }
      console.log(this.productDetail)
      // 替换换图片详情，用于懒加载
      // eslint-disable-next-line no-useless-escape
      let regexp1 = new RegExp(/(\<img )([^>]*)(src=")([^"]*")([^>]*)(\>)/, 'g')
      this.productDetail.description = this.productDetail.description.replace(regexp1, function(match, p1, p2, p3, p4, p5, p6) {
        return p1 + p2 + 'data-src="' + p4 + p5 + p6
      })
    },
    // 获取小程序码
    async getQrImg () {
      let token = window.localStorage.getItem('token')
      if (token == '' || token == null) {
        return false
      }
      if (this.pageInfo.pageStatus == 'seckill') {
        // 生成秒杀商品的小程序码
        const { data: res } = await this.$http.get(getSkillGoodsWechatCode,
          {
            params: {
              productId: this.addCartHttpObj.productId,
              activityId: this.pageInfo.activeId
            }
          })
        console.log(res)
        if (res.code !== 200) return this.$message.error(res.msg)
        this.qrImg = res.data
      } else {
        // 生成普通商品的小程序码
        const { data: res } = await this.$http.get(getNormalGoodsWechatCode,
          {
            params: {
              productId: this.addCartHttpObj.productId
            }
          })
        console.log(res)
        if (res.code !== 200) return this.$message.error(res.msg)
        this.qrImg = res.data
      }
    },
    // 商品详情左侧banner鼠标经过事件
    swiperItemHove (swiperItem) {
      this.bigProductImg = swiperItem.img
    },
    // 获取热门商品
    async getHotGoods () {
      const { data: res } = await this.$http.get(getHotGoods)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.hotGoodsList = res.data.list
    },
    // 商品介绍、商品评价切换事件
    clickIntroduce (index) {
      this.introduceIndex = index
    },
    // 商品数量加1
    addAmountCount () {
      if (this.chooseAmount >= this.productDetail.totalStock) {
        this.chooseAmount = this.productDetail.totalStock
        return this.$message.error('商品数量不能大于库存数量')
      }
      this.chooseAmount++
    },
    // 商品数量减1
    subtractAmountCount () {
      if (this.chooseAmount <= 1) {
        return this.$message.error('商品数量不能小于0')
      }
      this.chooseAmount--
    },
    // 立即购买
    toBuy () {
      let token = window.localStorage.getItem('token')
      if (token == '' || token == null) {
        this.$router.push('/login')
        return false
      }
      if (this.chooseAmount > this.productDetail.totalStock) {
        return this.$message.error('购买数量大于库存数量')
      }
      console.log(this.userCheckSku)
      if (this.singleSpecificationStatus) {
        // 单规格
        this.goodsDirectBuyHttpObj.sku[0].amount = this.chooseAmount
        this.goodsDirectBuyHttpObj.sku[0].productId = this.productDetail.id
        this.goodsDirectBuyHttpObj.sku[0].skuId = 0
      } else {
        // 多规格
        this.goodsDirectBuyHttpObj.sku[0].amount = this.chooseAmount
        this.goodsDirectBuyHttpObj.sku[0].productId = this.productDetail.id
        this.goodsDirectBuyHttpObj.sku[0].skuId = this.userCheckSku.id
      }
      this.$store.commit('changeGoodsDirectBuyHttpObjMutations', this.goodsDirectBuyHttpObj)
      this.$router.push({ path: '/editOrderInfo', query: { type: 'goods' } })
    },
    // 发起助力
    async boostLaunch () {
      let token = window.localStorage.getItem('token')
      if (token == '' || token == null) {
        this.$router.push('/login')
        return false
      }

      let httpObj = {
        id: this.pageInfo.boostId,
        skuId: 0
      }
      if (!this.singleSpecificationStatus) {
        httpObj.skuId = this.userCheckSku.id
      }
      const { data: res } = await this.$http.put(boostLaunchUrl + this.pageInfo.boostId, httpObj)
      console.log(res)
      if(res.code !== 200) return this.$message.error(res.msg)
      this.$message.success('发起助力成功，2秒后将自动跳转至我的助力详情页面')
      setTimeout(()=>{
        this.$router.push({ path: '/boostLaunchDetail', query: { boostId: res.data.id } })
      },2000)
    },

    // 秒杀立即购买
    async seckillBuyNow () {
      let token = window.localStorage.getItem('token')
      if (token == '' || token == null) {
        this.$router.push('/login')
        return false
      }
      let httpObj = {}
      if (this.singleSpecificationStatus) {
        // 如果是单规格
        httpObj = {
          sku: [{
            amount: 1,
            productId: this.productDetail.id,
            skuId: 0,
            activityId: this.pageInfo.activeId
          }]
        }
      } else {
        // 多规格
        httpObj = {
          sku: [{
            amount: 1,
            productId: this.productDetail.id,
            skuId: this.userCheckSku.id,
            activityId: this.pageInfo.activeId
          }]
        }
      }
      this.$store.commit('changeGoodsDirectBuyHttpObjMutations', httpObj)
      this.$router.push({ path: '/editOrderInfo', query: { type: 'seckill' } })
    },
    // 加入购物车
    async addCart () {
      let token = window.localStorage.getItem('token')
      if (token == '' || token == null) {
        this.$router.push('/login')
        return false
      }
      if (this.chooseAmount > this.productDetail.totalStock) {
        return this.$message.error('加入购物车数量不能大于库存数量')
      }
      this.addCartHttpObj.amount = this.chooseAmount
      this.addCartHttpObj.skuId = this.userCheckSku.id
      const { data: res } = await this.$http.post(addToCartUrl, this.addCartHttpObj)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.$message.success('加入购物车成功')
      await this.$store.dispatch('changeCartNumActions')
    },
    // 选择商品规格
    skuLabelClick (item, itemLabel, index) {
      // 把点击的那一列checked重置为false
      for (let i = 0; i < item.skuLabel.length; i++) {
        item.skuLabel[i].checked = false
      }
      // 让点击的哪一个checked变为true
      item.skuLabel[index].checked = true
      this.userChooseSku[item.skuKey] = itemLabel.name
      for (let i = 0; i < this.goodsInfo.goodsSkusList.length; i++) {
        if (this.isObjectValueEqualNew(this.userChooseSku, this.goodsInfo.goodsSkusList[i].productSpecs)) {
          this.userCheckSku = this.goodsInfo.goodsSkusList[i]
        }
      }
    },
    // 判断对象键和值是否相等
    isObjectValueEqualNew (a, b) {
      // 判断两个对象是否指向同一内存，指向同一内存返回true
      if (a === b) return true
      // 获取两个对象键值数组
      let aProps = Object.getOwnPropertyNames(a)
      let bProps = Object.getOwnPropertyNames(b)
      // 判断两个对象键值数组长度是否一致，不一致返回false
      if (aProps.length !== bProps.length) return false
      // 遍历对象的键值
      for (let prop in a) {
        // 判断a的键值，在b中是否存在，不存在，返回false
        if (Object.prototype.hasOwnProperty.call(b, prop)) {
          // 判断a的键值是否为对象，是则递归，不是对象直接判断键值是否相等，不相等返回false
          if (typeof a[prop] === 'object') {
            // if (!this.isObjectValueEqualNew(a[prop], b[prop])) return false
          } else if (a[prop] !== b[prop]) {
            return false
          }
        } else {
          return false
        }
      }
      return true
    },
  },
  watch: {
    // 深度监听路由改变事件
    '$route': {
      handler () {
        this.pageInfo = {
          pageStatus: '',
          productStatus: '',
          activeId: ''
        }
        this.initData()
      },
    }
  },
}
</script>

<style lang="less" scoped>
  .icon-qianjin {
    font-size: 12px;
  }

  .left-product-banner {
    border: 1px solid #dddddd;
    width: 398px;

    .big-img {
      width: 398px;
      height: 398px;
      display: block;
      margin: 0 auto;
    }

    .swiper-box {
      margin: 0 auto;

      .swiper-button {
        position: relative;

        i {
          top: 24px;
          position: absolute;
          font-size: 36px;
          color: #666666;
          z-index: 2;
          cursor: pointer;
        }

        .el-icon-arrow-left {

        }

        .el-icon-arrow-right {
          right: 0;
        }
      }

      .swiper-list {
        padding: 10px 50px;
        overflow: hidden;
        border-top: 1px solid #dddddd;
        height: 60px;

        .swiper-item {
          float: left;
          width: 58px;
          height: 58px;
          margin-right: 14px;
          margin-bottom: 20px;
          border: 1px solid transparent;
        }

        .swiper-item:hover {
          border: 1px solid #EF001C;
        }
      }
    }
  }

  .right-product-info {
    width: 780px;
    margin-left: 20px;

    .product-name {
      font-size: 16px;
      color: #555;
      font-weight: 700;
      margin-bottom: 10px;
      line-height: 30px;
      height: 60px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
    }

    .count-down-bg {
      width: 780px;
      background: url(../../assets/img/seckilling.png) repeat;
    }

    .other-info {
      background: url(../../assets/img/summary-bg.jpg) #f3f3f3 repeat-x 20px -60px;
      padding: 20px;
      height: 60px;

      .top {
        .price-info {
          float: left;
          font-size: 12px;
          color: #999999;

          span {
            font-size: 30px;
            height: 24px;
            line-height: 24px;
            color: #EF001C;
          }
        }

        .tip {
          width: 52px;
          height: 20px;
          line-height: 20px;
          background: url(../../assets/img/dsc-icon.png) -296px -44px no-repeat;
          display: inline-block;
          padding-left: 8px;
          color: #fff;
          margin: 3px 0 0 8px;
          cursor: pointer;
          font-size: 12px;
        }

        .product-sales {
          float: right;
          padding-right: 10px;
          padding-left: 10px;
          font-size: 14px;
          line-height: 14px;
          border-left: 1px solid #d2d2d2;

          span {
            color: #EF001C;
          }
        }
      }

      .bottom {
        .price-info {
          float: left;
          font-size: 12px;
          color: #999999;

          span {
            text-decoration: line-through;
          }
        }

        .phone-info {
          float: right;
          color: #666666;
          font-size: 14px;
          cursor: pointer;

          i {
            margin-left: 5px;
          }
        }
      }
    }

    .product-label {
      font-size: 12px;
      line-height: 24px;
      color: #666666;

      .label-title {
        display: inline-block;
        width: 56px;
        padding-left: 20px;
      }

      .label-content {
        display: inline-block;
        margin-right: 10px;

        .amount-warp {
          display: flex;

          input {
            width: 50px;
            height: 31px;
            line-height: 30px;
            padding-left: 10px;
            outline: none;
          }

          .quick-button {
            i {
              display: block;
              width: 30px;
              height: 16px;
              border-left: none;
              text-align: center;
              line-height: 16px;
              cursor: pointer;
            }

            .el-icon-arrow-up {
              border-bottom: none;
            }

            .el-icon-arrow-down {
            }
          }

          .quick-button i:hover {
            color: #EF001C;
          }
        }
      }

    }

    .sku-box {
      display: flex;

      .sku-title {
        height: 32px;
        line-height: 32px;
        margin-right: 10px;
        font-size: 14px;
        color: #666666;
        min-width: 60px;
      }

      .el-tag {
        cursor: pointer;
        font-size: 14px;
      }

      .label-check {
        background-color: #EF001C;
        color: #ffffff;
      }
    }
  }

  .product-details {
    max-width: 950px;
  }
</style>
