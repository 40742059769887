import { render, staticRenderFns } from "./RightGoodsList.vue?vue&type=template&id=a78ea8ac&scoped=true&"
import script from "./RightGoodsList.vue?vue&type=script&lang=js&"
export * from "./RightGoodsList.vue?vue&type=script&lang=js&"
import style0 from "./RightGoodsList.vue?vue&type=style&index=0&id=a78ea8ac&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a78ea8ac",
  null
  
)

export default component.exports